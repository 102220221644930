.desktop-layout {
  .logo {
    margin-bottom: 3px;

    img {
      width: 65px;
      height: 70px;
    }
  }
}

.btn-game-bet {
  // background-color: #383838 !important;
  font-size: 16px;
  position: fixed;
  z-index: 99999;
  left: 110px;
  top: 15px;
  margin: 0;
  width: 130px;
}

@media screen and(max-width: 768px) {
  .btn-game-bet {
    left: initial;
    right: 80px;
    top: 10px;
  }
}

.notice {
  display: flex;
  padding: 10px;
  background-color: var(--bg-color);

  .left {
    display: flex;
    align-items: center;
    flex: 1;
    background-color: var(--body-color);
    padding: 5px 10px;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 20px;
      height: auto;
    }

    svg {
      color: #8897ad;
      font-size: 14px;
    }

    span {
      font-size: 13px;
      color: #fff;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}

.title-home {
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 600;
}

.top-coin {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 10px 10px 0;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: calc(33% - 8px);
    background-color: var(--bg-color);
    color: var(--text-color);
    border-radius: 8px;

    h5 {
      font-size: 12px;

      b {
        color: #4e4e4e;
        font-size: 16px;
      }
    }

    p {
      font-size: 12px;
    }

    h4 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.quick-action {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 10px;
  margin: 10px 0;
  background-color: var(--bg-color);

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(50% - 8px);
    color: var(--text-color);
    height: 80px;
    border-radius: 5px;

    img {
      width: 25px;
      height: 25px;
      object-fit: contain;
    }

    p {
      text-align: center;
      font-size: 12px;
      color: #353535;
      margin-top: 5px;
      font-weight: 500;
    }
  }
}

.service {
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  box-sizing: border-box;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color);
    color: var(--text-color);
    height: 75px;
    width: 50%;
    padding: 10px 20px;

    img {
      width: 25px;
      height: auto;
    }

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      padding: 8px;
    }
  }
}

.quick-buy {
  margin-top: 10px;
  padding: 0 10px;

  .wrapper {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    color: var(--text-color);
    gap: 10px;
    width: 100%;

    .col1 {
      flex: 1;
    }

    .col2 {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        justify-content: flex-start;
        gap: 10px;

        p {
          font-weight: 500;
          font-size: 13px;
          color: #1b1b1b;
        }

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    .item {
      color: var(--text-color);
      background-color: var(--bg-color);
      border-radius: 6px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      height: auto;
      padding: 10px;

      img {
        height: 80px;
        width: auto;
        object-fit: contain;
        opacity: 0.8;
      }
    }
  }

  h5 {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    color: #1b1b1b;
  }

  p {
    color: #1b1b1b;
    line-height: 15px;
  }

  img {
    width: 100px;
  }
}

.banner-notice {
  margin-top: 10px;
  padding: 10px 10px 0;
  background-color: var(--bg-color);

  .item {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    width: 99%;
    height: 99px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;

    h5 {
      position: absolute;
      left: 15px;
      top: 10px;
      font-size: 28px;
      font-weight: 600;
    }

    p {
      position: absolute;
      left: 15px;
      top: 50px;
      font-size: 14px;
    }
  }
}

.home-page {
  padding-bottom: 80px;
}

.market-page {
  padding-top: 45px;

  .coin-list {
    .list {
      padding: 0 10px;

      .item {
        background-color: var(--bg-color);
        height: 70px;
        margin-bottom: 8px;
        border-radius: 5px;

        .col1 {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          img {
            width: 25px;
            height: auto;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.coin-list {
  p {
    font-size: 16px;
    font-weight: 600;
  }

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      background-color: var(--bg-color);
      color: var(--text-color);
      font-size: 16px;
      padding: 0 10px;

      &.tab {
        color: #5a5a5a;

        .active {
          color: #0052cc;
        }

        .col1 {
          justify-content: center;
          font-size: 14px;
          flex: 1;
        }

        .col2 {
          justify-content: center;
          font-size: 14px;
          flex: 1;
        }
      }

      &.header {
        background-color: var(--body-color);
        color: #6f7072;
        font-size: 12px;
        font-weight: 400;
        height: 35px;
      }

      .col1 {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        flex: 1.5 1 0%;

        .name {
          line-height: 18px;
        }

        .help {
          font-size: 13px;
          color: #5a5a5a;
          font-weight: 400;
          line-height: 16px;
        }

        b {
          font-size: 15px;
          font-weight: 600;
          line-height: 14px;
        }

        span {
          font-size: 12px;
          font-weight: 400;
          color: #5a5a5a;
          line-height: 14px;
        }
      }

      .col2 {
        display: flex;
        justify-content: flex-start;
        flex: 1;
        font-size: 14px;
      }

      .col3 {
        display: flex;
        justify-content: flex-end;
        flex: 1;
      }
    }

    .tag {
      border: 2px;
      width: 70px;
      padding: 8px;
      height: 26px;
      font-size: 13px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;

      &.green {
        background-color: #00a876;
      }

      &.red {
        background-color: #db3030;
      }

      &.blue {
        background-color: #00abff;
      }
    }
  }
}

.text-green {
  color: #02c289 !important;
}

.bg-green-800 {
  background-color: var(--bg-color) !important;
}

#general {
  padding-bottom: 80px;

  .card-header {
    background-color: #3677d8;
    color: #ffffff;
    box-shadow: none;
    border: none;
    outline: 0;
    text-align: center;
    position: relative;
    overflow: initial;
    margin-bottom: 75px;
    // background-image: url("../img/banner/banner_wallet.png");
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    background-image: radial-gradient(
      circle,
      #005cc7,
      #0053b3,
      #004a9f,
      #00418b,
      #023878
    );

    .sbui-card-content {
      padding: 3rem 2rem;
    }
  }

  .list-token {
    padding: 0 10px;

    .sbui-card {
      border-radius: 6px;
      margin-bottom: 10px;
    }
  }

  .sbui-card {
    box-shadow: none;
    border: none;
  }

  .quick-action {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px 10px;
    margin: 10px 0;
    background-color: #ffffff;
    border-radius: 15px 15px 0 0;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: calc(33% - 8px);
      color: var(--text-color);
      height: 80px;
      border-radius: 5px;

      img {
        width: 40px;
        height: auto;
      }

      p {
        color: #4e4e4e;
        font-weight: 400;
      }
    }
  }
}

#withdraw {
  .card {
    background-color: #f7f6fb;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.input-exchange {
  display: flex;
  align-items: center;
  gap: 10px;

  .sbui-input {
    width: calc(100% - 150px);
    border: none;
    box-shadow: none;
    outline: 0;
    font-size: 16px;
    padding: 4px 0;
  }

  .btn-all {
    background: transparent;
    color: rgb(0, 122, 255);
    font-size: 16px;
    width: 100px;
    padding: 0;
  }
}

.exchange-info {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 5px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 auto;
    gap: 6px;
    width: calc(33% - 5px);
    font-size: 12px;
    color: #303133;
  }
}

.list-coin-deposit {
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    color: var(--text-color);
    border-bottom: 1px solid #e4e4e4;

    &:hover {
      background-color: #8897ad;
      color: #ff9100;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-size: 16px;
        color: #353535;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    svg {
      color: #7c7c7c;
      font-size: 14px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.classic-bet-now {
  .content {
    width: 100%;
  }

  .count-time {
    font-size: 50px;
    font-weight: 600;
    color: #0064a7;
    text-shadow: 2px 2px 3px #bbbbbb;
  }

  .table-info {
    width: 100%;

    .item {
      display: flex;

      span {
        flex: 1;
        padding: 5px;
        font-size: 14px;
        color: #4a7697;
        text-align: left;

        &:first-child {
          font-weight: 600;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.cskh-iframe {
  height: calc(100vh - 120px);
}

.cskh-icon {
  position: fixed;
  right: 15px;
  bottom: 80px;
  z-index: 999;
  width: 50px;
  height: 50px;
  background: #1c8bf3;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 28px;
    filter: invert(100%) sepia(0%) saturate(3447%) hue-rotate(41deg)
      brightness(2000%);
  }
}

.btn-download {
  background: transparent;
  width: 150px;
  overflow: hidden;
  border-radius: 10px;

  img {
    width: 100%;
    height: auto;
  }
}

.tab-head {
  position: relative;

  &.tabs {
    width: 100%;

    .tab {
      flex: 1;
    }
  }
}

.search-container {
  display: flex;
  align-items: center;
  background: #f7f6fb;
  border-radius: 5px;
  width: 220px;
  padding: 0 15px;
  height: 35px;
  margin: 10px 0;

  input {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    height: 35px;
    color: #0b1622;
    font-size: 14px;

    &:focus {
      border: none;
      outline: 0;
      box-shadow: none;
    }
  }

  svg {
    font-size: 14px;
  }
}

.btn-register {
  background: #0a0d11;
  border: none;
  outline: 0;
  font-weight: 400;
}

.text-primary {
  color: #0052cc;
}

#logo {
  height: 60px;
  width: auto;
  margin: 0 auto;
}

.auth-page {
  overflow: hidden;
  overflow-y: auto;

  .sbui-sidepanel {
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.maintenance-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 45px;
    font-size: 45px;
    color: #fffb00;
    z-index: 999;
  }
}

.trade-page {
  background-color: #131c2e;
  padding-top: 10px;
  padding-bottom: 150px;
  box-sizing: border-box;

  &.light {
    background-color: #ffffff;

    .price-info .col2 > div h5 {
      color: #0b1622;
    }

    .action-group .btn-history {
      color: #0b1622;
    }

    .action-group .coin {
      color: #2f4053;

      b {
        color: #0b1622;
      }
    }

    .chart-time .btn {
      border: 1px solid #0b1622;
      height: 40px;
      color: #0b1622;

      &.active {
        border-bottom: 3px solid #4649f7;
      }
    }

    .trade-header .tabs .tab {
      color: #0b1622;

      &.active {
        background-color: #d3d3d3;
        color: #0b1622;
      }
    }

    .trade-footer {
      background-color: #ffffff;
      color: #0b1622;

      .sbui-checkbox__label-container__label {
        color: #0b1622;
      }
    }
  }

  .trade-header {
    .tabs {
      background: transparent;
      justify-content: center;

      .tab {
        border: 1px solid #c7c7c7;
        color: #c7c7c7;
        font-size: 12px;
        padding: 5px 10px;

        &.active {
          color: #ffffff;
          background-color: #bdbdbd;

          &::after {
            display: none;
          }
        }
      }
    }
  }

  .action-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;

    .coin {
      color: #bdbdbd;
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 13px;
      font-weight: 400;

      b {
        color: #e9e9e9;
        font-weight: 600;
      }

      svg {
        color: #b4b4b4;
      }
    }

    .btn-theme {
      width: 25px;
      height: 25px;
      min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      svg {
        font-size: 14px;
      }

      &.light {
        background-color: #ffffff;
        color: #0b1622;
      }

      &.dark {
        background-color: #0b1622;
        color: #ffffff;
      }
    }

    .btn-history {
      background: transparent;
      color: #e9e9e9;
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 5px;
      width: auto;
      font-weight: 400;
      min-height: 25px;
    }
  }

  .price-info {
    display: flex;
    padding: 15px 20px;

    .col1 {
      width: 50%;

      p {
        font-size: 13px;
        color: #62768a;
        line-height: 24px;
      }

      h5 {
        font-size: 14px;
        line-height: 24px;
      }

      h4 {
        font-size: 22px;
        line-height: 35px;
        font-weight: 500;
      }

      .text-green {
        color: #00a876 !important;
      }

      .text-red {
        color: #d45b52 !important;
      }
    }

    .col2 {
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      > div {
        width: calc(50% - 5px);

        p {
          font-size: 13px;
          color: #62768a;
        }

        h5 {
          font-size: 14px;
          color: #d4d4d4;
        }
      }
    }
  }

  .chart-time {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 10px;
    width: 90%;
    box-sizing: border-box;

    .btn {
      background: transparent;
      min-height: 32px;
      height: 32px;
      width: 50px;
      border-radius: 4px;
      color: #e0e0e0;

      &.active {
        border-bottom: 2px solid #4649f7;
      }
    }
  }
}

.trade-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 50%;
  bottom: 55px;
  width: 100%;
  height: 50px;
  transform: translateX(-50%);
  max-width: 500px;
  background-color: #131c2e;
  border-top: 1px solid #ffffff;
  padding: 10px 15px;

  .sbui-checkbox-container {
    align-items: center;
  }

  .sbui-checkbox-container--medium .sbui-checkbox {
    margin-right: 6px;
    height: 20px;
    width: 20px;
  }

  .sbui-checkbox__label-container {
    line-height: 12px;
  }

  .sbui-checkbox__label-container__label {
    color: #ffffff;
    font-weight: 400;
    font-size: 13px !important;
    line-height: 13px !important;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    z-index: 9999;
  }

  .btn-buy {
    border: none;
    box-shadow: none;
    outline: 0;
    background: #1ab184;
    color: #ffffff;
    border-radius: 15px;
    padding: 0 15px;
    height: 40px;
    font-size: 14px;
  }

  .btn-sell {
    border: none;
    box-shadow: none;
    outline: 0;
    background: #fa6767;
    color: #ffffff;
    border-radius: 15px;
    padding: 0 15px;
    height: 40px;
    font-size: 14px;
  }
}

.member-page {
  padding-bottom: 80px;

  .container_main {
    position: relative;
    margin-top: -70px;
    z-index: 99;
  }
}

.member-header {
  color: #ffffff;
  box-shadow: none;
  border: none;
  outline: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: initial;
  height: 320px;
  background-image: radial-gradient(
    circle,
    #005cc7,
    #0053b3,
    #004a9f,
    #00418b,
    #023878
  );
  // background-image: url("../img/banner/bg_signin_top.png");
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;

  z-index: 1;

  .logo {
    background-color: #f1ca16;
    padding: 0px 10px;
    width: 100px;
    height: auto;
    margin-bottom: 10px;
  }

  .btn-setting {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #eaecee;
    font-size: 18px;
  }

  .avatar {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 50%;
  }

  .username {
    font-size: 18px;
    font-weight: 500;
    color: #ececec;
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  .ref {
    line-height: 16px;
    font-size: 12px;
    padding: 5px 10px;
    width: 100px;
    background-color: #fecf53;
    color: #272727;
    font-weight: 600;
    text-align: center;
    margin-left: 10px;
    border-radius: 20px;
  }
}

.memberMenu {
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  z-index: 10;

  li {
    padding: 12px;
    border-bottom: 1px solid #f3f3f3;

    a,
    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: rgba(27, 35, 51, 0.9);

      > div {
        display: flex;
        align-items: center;
      }

      svg {
        font-size: 20px;
        width: 16px;
        color: rgba(76, 94, 109, 0.6);
      }

      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }

      span {
        margin-left: 12px;
        font-size: 13px;
      }

      .angle-right {
        font-size: 12px;
        color: rgba(76, 94, 109, 0.6);
      }
    }
  }
}

.btn-logout {
  display: flex;
  padding: 10px;
  font-size: 16px;
  margin: 0 auto;
  border-radius: 25px;
  min-width: 200px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.feature-page {
  background-color: #0b1622;
  padding: 55px 10px 80px;

  .feature-header {
    .action-group {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .coin {
        p {
          color: #bbbbbb;
          font-size: 12px;

          b {
            font-size: 22px;
            color: #e9e9e9;
          }

          .price {
            margin-left: 5px;
            font-size: 22px;
          }
        }

        h5 {
          margin-top: 5px;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }

    .btn-history {
      background: #5a6770;
      color: #e5ecf5;
      font-size: 12px;
      border-radius: 0;
      padding: 4px 10px;
      width: auto;
      min-height: 25px;
      height: 25px;
    }
  }

  .feature-board {
    font-size: 11px;
    padding-right: 5px;

    .tabs {
      background: transparent;
      margin-bottom: 10px;

      .tab {
        color: #6f7072;
        font-size: 11px;
        padding: 5px 10px;

        &.active {
          color: #cccccc;

          &::after {
            height: 2px;
            width: 30px;
            background-color: #cccccc;
          }
        }
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      color: #cccccc;
    }

    .group-action {
      display: flex;
      margin-bottom: 10px;

      .btn {
        background: transparent;
        border: 1px solid #717b83;
        color: #cccccc;
        border-radius: 100px;
        font-size: 12px;
        min-height: 28px;
        height: 28px;
        padding: 0 10px;

        &.btn-buy {
          &.active {
            background-color: #00d192;
            color: #ffffff;
            border: none;
          }
        }

        &.btn-sell {
          &.active {
            background-color: #fa6767;
            color: #ffffff;
            border: none;
          }
        }
      }
    }

    .quantity {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 5px;

      .btn {
        flex: 1;
        width: auto;
        min-width: calc(20% - 5px);
        max-width: calc(25% - 5px);
        min-height: 28px;
        height: 28px;
        padding: 5px;
        font-size: 12px;
        border-radius: 4px;
        background: transparent;
        border: 1px solid #1e2630;
        color: #cccccc;

        &.active {
          background-color: #293750;
          color: #d3dce9;
          border: none;
        }
      }
    }

    .qty-info {
      display: flex;
      gap: 5px;
      color: #cccccc;

      span {
        &:first-child {
          width: 50px;
        }
      }

      .show {
        background-color: #293750;
        padding: 5px;
        width: calc(100% - 50px);
        text-align: center;
        height: 25px;
        border-radius: 4px;
      }
    }

    .qty-input {
      display: flex;
      align-items: center;
      width: 70%;
      border-radius: 4px;
      overflow: hidden;

      .btn {
        width: 32px;
        min-height: 32px;
        height: 32px;
        background-color: #293750;
        border: none;
        color: #cccccc;
        border-radius: 0;
        font-size: 16px;
      }

      .sbui-input {
        background-color: #293750;
        border-top: none;
        border-bottom: none;
        border-left: 1px solid #1e2630;
        border-right: 1px solid #1e2630;
        color: #cccccc;
        height: 32px;
        border-radius: 0;
        width: calc(100% - 64px);
        text-align: center;
      }
    }
  }

  h5 {
    color: #cccccc;
    width: 50px;
  }

  h4 {
    font-size: 16px;
    color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .diver {
    background: #24282c;
  }

  .price-history {
    padding-left: 5px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 6px;

      p {
        color: #dddddd;
        font-size: 12px;
      }
    }
  }

  .trade-history {
    margin-top: 10px;
    min-height: 350px;

    .tabs {
      background: transparent;
      padding: 0;
      border-bottom: 1px solid #9b9b9b;

      .tab {
        color: #6f7072;
        font-size: 11px;
        padding: 5px 8px;
        flex: 1 auto;

        &.active {
          color: #667dff;

          &::after {
            display: none;
            background-color: #cccccc;
          }
        }
      }
    }

    .table-data {
      color: #dddddd;
      font-size: 11px;
      font-weight: 400;

      .text-left {
        text-align: left;
      }

      .text-right {
        text-align: right;
      }

      th {
        td {
          padding: 5px;
          font-weight: 400;
          vertical-align: top;
        }
      }

      tbody {
        min-height: 350px;
      }
    }
  }

  .noRecord {
    color: #dddddd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    svg {
      font-size: 40px;
    }
  }
}

.diver {
  width: 100%;
  height: 1px;
  background: #e9e9e9;
  margin: 8px 0;
}

.spot-page {
  .feature-header {
    .action-group {
      .coin {
        p {
          font-size: 16px;

          span {
            margin-right: 5px;
          }

          b {
            font-size: 16px;
            color: #e9e9e9;
          }
        }
      }
    }
  }

  .feature-board {
    margin-top: 10px;

    .group-action {
      .btn {
        border-radius: 0;
      }
    }

    .type-select {
      select {
        color: #e9e9e9;
        background-color: #293750;
        border: none;
        width: 100%;
      }
    }

    .quantity-type {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin-bottom: 5px;

      .btn {
        position: relative;
        flex: 1;
        width: auto;
        min-width: calc(20% - 5px);
        max-width: calc(25% - 5px);
        min-height: 28px;
        height: 40px;
        padding: 5px;
        font-size: 12px;
        border-radius: 4px;
        background: transparent;
        border: none;
        outline: 0;
        color: #768094;

        &:hover,
        &:focus {
          box-shadow: none;
          border: none;
          outline: 0;
          background: transparent;
          opacity: 1;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 5px;
          width: 80%;
          background-color: #293750;
        }

        &.active {
          color: #ffffff;
          border: none;

          &::after {
            background-color: #00a876;
          }
        }
      }
    }
  }

  .button-footer {
    .btn-buy {
      border-radius: 0;
      background-color: #00d192;
      color: #ffffff;
      border: none;
      width: 80%;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-sell {
      border-radius: 0;
      background-color: #fa6767;
      color: #ffffff;
      border: none;
      width: 80%;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .price {
    font-size: 18px;
    margin: 5px 0;
    color: #e9e9e9;
  }
}

.page-deposit {
  background-image: url("../img/cz-bg.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 130vh;
  height: 100%;
  width: 100%;

  .card {
    background: var(--bg-color);
    padding: 15px;
    border-radius: 10px;

    p {
      font-size: 14px;
    }
  }
}

.list-partner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
  max-width: 60%;
  margin-top: 20px;

  a {
    img {
      width: 100%;
    }
  }
}

.user-info {
  padding-top: 45px;
  padding-bottom: 60px;

  .btn-logout {
    width: 85%;
    border-radius: 25px;
    margin-top: 10px;
  }

  .memberForm {
    background-color: #ffffff;
    margin-bottom: 10px;
  }

  .memberMenu {
    padding: 0;
    margin: 0;

    li {
      padding: 18px 12px;
    }
  }
}

.introduce-page {
  padding-bottom: 80px;
}

.language-page {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #c8c7cc;

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #333;
      font-size: 14px;
    }
  }
}

.list-lang {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    background-image: linear-gradient(#fff, #e8e8e8);
    border: solid 1px #e5e5e5;
    margin-bottom: 10px;
    justify-content: center;
    border-radius: 6px;

    &.active {
      background: #3c475c;
      color: #fff;
      border: solid 1px #3c475c;

      span {
        color: #fff;
      }
    }

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #333;
      font-size: 16px;
    }
  }
}

.list-questions {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #c8c7cc;

    svg {
      font-size: 30px;
      color: #999;
    }

    span {
      color: #333;
      font-size: 14px;
    }
  }
}

.page-history {
  tr {
    background: #ffffff;
  }
}

.content-questions {
  padding: 10px;
  font-size: 14px;
  color: #1e2630;
}

.card-info {
  p {
    color: #1e2630;
    margin-bottom: 5px;
  }
}

.text-blue {
  color: #1c8bf3 !important;
}

.fund-page {
  background-image: url("../img/bg-fund.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 90px;

  .sbui-card {
    border-radius: 15px;
  }

  h3 {
    color: #ffffff;
    font-size: 22px;
    margin-bottom: 5px;
  }

  h5 {
    color: #ffffff;
    font-size: 14px;
  }

  .col1 {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 0 10px;
    margin-bottom: 10px;

    p {
      flex: 1 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      img {
        width: 43px;
        height: auto;
        margin-bottom: 5px;
      }

      b {
        font-size: 17px;
      }

      span {
        font-size: 13px;
      }
    }
  }
}

.btn-fund {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
  z-index: 99;
  background-color: #24acd4;
  color: #fff;
  width: 95%;
  padding: 10px 0;
  font-size: 18px;
}

.fund-detail-page {
  padding: 0 0 80px;

  .header-card {
    background-color: #3677d8;
    color: #ffffff;
    box-shadow: none;
    border: none;
    outline: 0;
    text-align: center;
    position: relative;
    overflow: initial;
    background-image: url("../img/banner/banner_wallet.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 0 10px !important;

    .head-pro-title {
      height: 133px;
      padding: 0 1rem;
    }

    .info-card {
      background-color: hsla(0, 0%, 100%, 0.2);
      -webkit-border-radius: 0;
      border-radius: 0;
      color: #fff;
    }

    .btn-history {
      position: fixed;
      right: -10px;
      top: 80px;
      width: 110px;
      min-height: 24px;
      -webkit-border-radius: 50px;
      border-radius: 50px;
      z-index: 99;
      text-align: center;
      background: #d5e7ff;
      color: #5a8af8;
      padding: 5px 10px;
      width: 120px;
      margin: 10px auto;
      font-size: 16px;
    }
  }
}

.fund-history {
  padding: 50px 0 80px;
}

.list-history-data {
  padding: 10px;
  width: 100%;

  .item {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px 10px 10px;
    width: 100%;
    margin-bottom: 10px;

    img {
      width: 42px;
      height: 42px;
    }

    .btn-fund-add {
      background: #00abff;
      margin: 0;
      color: #fff;
      border: 0;
      font-size: 11px;
      padding: 4px 11px;
      height: 30px;
      min-height: 30px;
    }
  }

  .btn-bet-game {
    padding: 5px 11px;
    color: #ffffff;
    width: 80px;
    border-radius: 20px;
    text-align: center;
    font-size: 13px;
    height: 28px;

    &.buy {
      background-color: #00a876;
    }

    &.sell {
      background-color: #bb0000;
    }
  }
}

.text-blue-500 {
  color: var(--theme-color) !important;
}

.text-yellow-500 {
  color: #f2c94c !important;
}

.fund-detail {
  background-image: url("../img/bg-fund-2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-color: #0052cc;
  color: #ffffff;
  padding: 50px 15px 80px;

  .fund-head {
    padding: 1rem 0;

    img {
      width: 25px;
      height: 35px;
    }
  }
}

.card-header {
  width: 90%;
  background-color: #ffffff;
  color: #303133;
  margin: 0 auto;
  border-radius: 2px;
  padding: 15px;
}

.section-info {
  background-color: hsla(0, 0%, 100%, 0.2);
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #fff;
  padding: 15px;
  width: 100%;

  &.section-1 {
    padding: 60px 15px 15px;
    margin-top: -40px;
  }
}

.input-fund {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #ffffff;
  padding: 5px 11px;
  color: #1f2126;

  .sbui-input {
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 600;
    padding: 5px;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  .btn-all {
    color: #00abff;
    font-size: 15px;
    background: transparent;
    padding: 5px;
    width: 120px;
  }
}

.text-right {
  text-align: right;
}

.list-bank {
  margin-top: 15px;
  padding: 0 15px;

  .item {
    padding: 10px 0;
    border-bottom: 1px solid #585e66;
    margin-bottom: 15px;

    .card-icon {
      flex: none;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      margin-right: 8px;
      color: #ffffff;
      background: var(--theme-color);
      text-align: center;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      padding: 5px;
      box-sizing: border-box;
    }

    p {
      font-size: 14px;
      line-height: 14px;
    }

    .item-center {
      align-items: center;
    }
  }
}

.card-note {
  background-color: #ffffff;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 25px;
  margin-bottom: 5px;
}

.bank_footer {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 6px 1px #000;
  box-shadow: 0 0 6px 1px #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;

  .sbui-btn {
    display: block;
    width: 100%;
    text-align: center;
    background: var(--theme-color);
    color: #fff;
    line-height: 40px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 16px;
  }
}

.bank-page {
  padding-bottom: 80px;
}

.bank-info {
  margin-bottom: 10px;
}

.form__cell {
  display: flex;
  align-items: center;
  height: 50px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6e6e6;

  .form__label {
    flex: none;
    min-width: 8em;
    color: #000;
    font-size: 13px;
  }

  .form__field {
    -webkit-box-flex: 1;
    -webkit-flex: auto;
    flex: auto;

    .sbui-input {
      height: 40px;
      width: 100%;
      border: none;
      background: #f7f6fb;
    }
  }
}

.left-draw {
  position: fixed;
  left: 0;
  top: 0;
  width: 220px;
  height: 100vh;
  background: #ffffff;
  z-index: 99999;

  .content {
    position: relative;
    background: #ffffff;
    z-index: 99;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .title {
      color: #171c31;
      padding: 10px 15px;
      font-size: 14px;
      border-bottom: 1px solid #e6e6e6;
    }

    .coin-list {
      box-sizing: border-box;
      padding: 0 10px;

      .search-container {
        width: 100%;
      }
    }
  }
}

.btn-add-wallet {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  background: var(--theme-color) !important;
  width: 95%;
  border: none;
  -webkit-border-radius: 200px;
  border-radius: 200px;
  color: #fff !important;
  font-size: 16px;
  margin: 20px 0;
  height: 40px;
}

.sbui-sidepanel-container {
  &.register-page {
    background-color: #f7f6fb;

    .sbui-sidepanel {
      background: transparent;
    }

    .sbui-auth {
      padding: 5% 6%;
    }

    .tabs {
      background: transparent;

      .tab {
        font-size: 18px;
        color: #777;
        line-height: 23px;
        width: 50%;

        &.active {
          font-size: 23px;
          line-height: 28px;
          color: #0b1622;

          &::after {
            bottom: 0;
          }
        }
      }
    }

    .capcha {
      display: flex;
      align-items: center;

      img {
        height: 35px;
        width: auto;
      }
    }
  }
}

.feature-footer {
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  background-color: #ffffff;
  padding: 5px 10px;

  .btn-feature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 95%;
    color: #ffffff;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 16px;

    &.sell {
      background-color: #cd4e65;
    }

    &.buy {
      background-color: #1cad90;
    }
  }
}
